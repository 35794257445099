<template>
  <div>
    <div v-if="showArea === 'list'" class="list-area">
      <el-row>
        <el-button
          size="small"
          type="primary"
          v-has="'add'"
          icon="el-icon-plus"
          @click="handleOperate('create')"
          >添加</el-button
        >
        <ExportTable class="ml10" @download="getDownloadList"></ExportTable>
        <el-button
          size="small"
          type="primary"
          @click="refresh"
          style="margin-left: 10px"
          >刷新</el-button
        >
      </el-row>
      <el-row :gutter="6">
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.appCategoryId"
            placeholder="应用类型"
          >
            <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="searchData.appCategoryId === 1">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.subType"
            placeholder="子类型"
          >
            <el-option
              v-for="item in appSubTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.releaseType"
            placeholder="上架类型"
          >
            <el-option
              v-for="item in releaseTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.languageId"
            placeholder="语言"
          >
            <el-option
              v-for="item in languageList"
              :key="item.languageId"
              :value="item.languageId"
              :label="item.languageName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.releaseStatus"
            placeholder="应用状态"
          >
            <el-option
              v-show="item.key !== 1007"
              v-for="item in appStatusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.sourceType"
            placeholder="创建来源"
          >
            <el-option
              v-for="item in sourceList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="4">
          <el-select
            clearable
            v-model="searchData.businessName"
            filterable
            placeholder="请选择商务名称"
          >
            <el-option
              v-for="item in businessNameList"
              :key="item.id"
              :label="item.name"
              :value="`${item.id},${item.name}`"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-input
            size="small"
            placeholder="请输入搜索内容"
            v-model="serachKeyword"
            class="input-with-select"
          >
            <el-select
              size="small"
              slot="prepend"
              v-model="serachKeywordCode"
              placeholder="请选择类型"
            >
              <el-option label="APPID" :value="1"></el-option>
              <el-option label="应用名称" :value="2"></el-option>
              <el-option label="应用包名" :value="3"></el-option>
              <el-option label="实际开发者" :value="4"></el-option>
              <!-- <el-option label="开发者ID" :value="6"></el-option> -->
              <!-- <el-option label="投放者名称" :value="7"></el-option> -->
              <el-option label="投放者ID" :value="7"></el-option>
            </el-select>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 修改日期：</span>
            <el-date-picker
              size="small"
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4"
          ><el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          ></el-col
        >
      </el-row>
      <PageTable
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          slot="prefix"
          type="selection"
          width="50"
          align="center"
        >
        </el-table-column>
        <template slot-scope="{ row }" slot="appCategoryId">{{
          row.appCategoryId | typeDesc(appTypeList)
        }}</template>
        <template slot-scope="{ row }" slot="authDocStart">
          {{ row.authDocStart }}--{{ row.authDocEnd }}</template
        >
        <template slot-scope="{ row }" slot="releaseCountryCount">
          <span
            v-if="row.releaseCountry"
            class="textBtn"
            @click="handleCountry(row)"
            >{{ row.releaseCountry.split(",").length }}</span
          >
          <span v-if="!row.releaseCountry">0</span>
        </template>
        <template slot-scope="{ row }" slot="ratio">
          <span
            class="textBtn"
            v-if="row.ratio || row.ratio == 0"
            @click="handleRatio(row)"
            >{{ row.ratio }}</span
          >
          <span class="textBtn" v-else @click="handleRatio(row)">设置</span>
        </template>
        <template slot-scope="{ row }" slot="sourceType">{{
          row.sourceType | typeDesc(sourceList)
        }}</template>
        <template slot-scope="{ row }" slot="releaseType">{{
          row.releaseType | typeDesc(releaseTypeList)
        }}</template>
        <template slot-scope="{ row }" slot="releaseStatus">
          <el-tag
            size="small"
            :type="row.releaseStatus | tagType(appStatusList)"
          >
            {{ row.releaseStatus | typeDesc(appStatusList) }}
          </el-tag>
        </template>
        <template slot-scope="{ row }" slot="reviewStatus">
          <el-tag
            size="small"
            :type="row.reviewStatus | tagType(appReviewStatusList)"
          >
            {{ row.reviewStatus | typeDesc(appReviewStatusList) }}
          </el-tag>
        </template>
        <el-table-column
          label="操作"
          width="180px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              v-has="'lookinfo'"
              @click="handleOperate('view', row)"
            >
              <span class="colorGreen">查看</span>
            </el-button>
            <el-button
              type="text"
              v-has="'edit'"
              :disabled="row.sourceType == 0"
              @click="handleOperate('edit', row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-has="'history'"
              @click="handleOperate('history', row)"
              >历史版本</el-button
            >
            <el-popconfirm
              v-has="'onOff'"
              v-if="row.releaseStatus !== 0"
              :title="`确定${row.releaseStatus == 1003 ? '下架' : '上架'}吗？`"
              class="ml10"
              @confirm="handleChangeStatus(row)"
            >
              <el-button
                slot="reference"
                type="text"
                class="colorRed"
                :disabled="row.releaseStatus == 1002 && row.releaseType == 3"
              >
                <span>{{ row.releaseStatus == 1003 ? "下架" : "上架" }}</span>
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </PageTable>
      <div class="page-foot" v-has="'batchOnOff'">
        <el-select v-model="batchOperation" size="small" placeholder="批量操作">
          <el-option :value="0" label="批量上架"></el-option>
          <el-option :value="1" label="批量下架"></el-option>
        </el-select>
        <el-button size="small" type="primary" @click="handleBatchOperation"
          >提交操作</el-button
        >
      </div>
    </div>
    <Detail
      v-if="showArea === 'detail'"
      :operationType="operationType"
      :detailData="detailData"
      :releaseType="detailData.releaseType"
      :languageList="languageList"
      @submit="handleSubmit"
      @save="handleSave"
      @cancle="handleCancel('list')"
    />
    <el-dialog
      top="10vh"
      destroy-on-close
      :close-on-click-modal="false"
      :title="operationType | operationTypeDesc"
      :visible.sync="dialogVisible"
      @closed="handleResetCreate()"
    >
      <Create
        ref="createRef"
        v-if="operationType === 'create'"
        :languageList="languageList"
        @submit="handleCreateApp"
        @cancle="handleCancel('list')"
      />
      <History
        v-if="operationType === 'history'"
        :apkData="detailData"
        @view="(row) => handleOperate('view', row)"
        @appBack="hanldeAppVersionBack"
      />
    </el-dialog>
    <el-dialog
      center
      destroy-on-close
      title="编辑ratio"
      :visible.sync="ratioDialog"
    >
      <auditModel
        :detailData="editData"
        @submit="handleSubmitAudit"
        @cancel="ratioDialog = false"
      ></auditModel>
    </el-dialog>
    <el-dialog
      center
      destroy-on-close
      title="发布国家"
      :visible.sync="countryDialog"
    >
      <countryModel
        :detailData="courntryList"
        @submit="countryDialog = false"
        @cancel="countryDialog = false"
      ></countryModel>
    </el-dialog>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import {
  appTypeList,
  releaseTypeList,
  appStatusList,
  appSubTypeList,
  sourceList,
  appReviewStatusList,
  MAXSIZEDOWN,
} from "@/common/constant/constant.js";
import Create from "./Create.vue";
import Detail from "../apkManagement/Detail.vue";
import History from "./HistoryList.vue";
import auditModel from "./auditModel.vue";
import countryModel from "./countryModel.vue";
import ExportTable from "@/components/ExportTable.vue";
import {
  getApkList,
  createAppInfo,
  changeStatus,
  getLanguageList,
  appBack, // 版本回退
  saveTestInfo, // 内测
  submitTestInfo, // 内测
  appReservedSave, // 预约
  appReservedSubmit, // 预约
  submitReleaseInfo, // 普通
  saveUpdateReleaseInfo, // 普通
  queryGeneral, // 普通
  getReservedById, // 预约
  queryTest, // 内测
  editRatio,
  lookCountry,
} from "./indexServe";
import { getBusinessManage } from "@/views/system/setCenter/commerceManagement/indexServe";
import moment from "moment";
export default {
  name: "putinApkManagement",
  components: {
    PageTable,
    Create,
    Detail,
    History,
    auditModel,
    ExportTable,
    countryModel,
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      appTypeList,
      releaseTypeList,
      appStatusList,
      appSubTypeList,
      sourceList,
      appReviewStatusList,
      serachKeyword: "",
      serachKeywordCode: 2,
      searchData: {
        date: [start, end],
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "appId",
          label: "APPID",
          width: "100px",
          sortable: true,
          fixed: "left",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "title",
          label: "应用名称",
          fixed: "left",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "packageName",
          label: "应用包名",
          align: "center",
          width: 100,
          "show-overflow-tooltip": true,
        },
        { slot: "appCategoryId", label: "应用类型", align: "center" },
        { key: "versionName", label: "版本", align: "center" },
        { key: "companyName", label: "开发者名称", align: "center" },
        { key: "name", label: "实际开发者", align: "center" },
        { slot: "authDocStart", label: "授权书有效期", align: "center" },
        {
          key: "createTime",
          label: "上架时间",
          width: "150px",
          align: "center",
        },
        {
          key: "modifyTime",
          label: "修改时间",
          width: "150px",
          align: "center",
        },
        {
          slot: "releaseCountryCount",
          label: "发布国家",
          width: "130px",
          align: "center",
        },
        {
          slot: "sourceType",
          label: "创建来源",
          align: "center",
          width: 120,
        },
        {
          slot: "releaseStatus",
          label: "应用状态",
          align: "center",
        },
        {
          slot: "releaseType",
          label: "上架类型",
          align: "center",
        },
        { slot: "ratio", label: "ratio", align: "center" },
        { key: "popularity", label: "知名度", align: "center" },
        { key: "performance", label: "表现", align: "center" },
        { key: "businessName", label: "商务", align: "center" },
      ],
      visible: false, //控制drawer的显示和隐藏
      dialogVisible: false, // 控制dialog的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      showArea: "list", // 记录当前显示的区域，列表-list，应用详情-detail
      batchOperation: "", // 批量操作
      selectionIdList: [], //所有已选的id列表
      detailData: {},
      languageList: [],
      businessNameList: [],
      ratioDialog: false,
      countryDialog: false,
      courntryList: [],
      editData: {},
    };
  },
  mounted() {
    // this.init();
  },
  activated() {
    if (this.$route.query.creatorId) {
      this.serachKeywordCode = 7;
      this.serachKeyword = this.$route.query.creatorId; //从开发者列表过来时携带的有creatorId字段(开发者列表的用户id字段) 隐藏传给了后端
      this.searchData.date = [];
    } else {
      this.serachKeywordCode = "";
      this.serachKeyword = "";
    }
    this.init();
    getBusinessManage({ page: 1, size: 3000 }).then((res) => {
      if (res.code == 0) {
        this.businessNameList = res.data.list;
      }
    });
  },
  methods: {
    refresh() {
      window.location.reload();
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    async handleOperate(operationType, row) {
      this.operationType = operationType;
      if (["create", "history"].includes(operationType)) {
        this.dialogVisible = true;
        this.detailData = { ...row };
      } else if (["edit", "view"].includes(operationType)) {
        this.showArea = "detail";
        this.dialogVisible = false;
        const detailData = await this.getDetail(
          { releaseId: row.releaseId, sourceType: row.sourceType },
          row.releaseType
        );
        this.detailData = {
          releaseStatus: row.releaseStatus, // 应用状态
          releaseType: row.releaseType,
          appReleaseId: row.releaseId,
          sourceType: row.sourceType, //来源类型(0开发者平台 1运营平台)
          appId: row.appId,
          id: row.id,
          ...detailData,
        };
      }
    },
    hanldeAppVersionBack(row) {
      this.dialogVisible = false;
      if ([1002, 1003].includes(row.releaseStatus)) {
        // 已上架&待上架的应用更改应用状态
        this.handleChangeStatus(row);
      } else {
        this.appBack({ releaseId: row.releaseId });
      }
    },
    handleCreateApp(formData) {
      createAppInfo(formData).then((res) => {
        if (res && res.code === 0) {
          this.showArea = "detail";
          this.dialogVisible = false;
          this.detailData = {
            ...res.data,
            releaseType: formData.releaseType,
            appReleaseId: res.data.editReleaseId,
            appType: res.data.appCategoryId,
            appName: res.data.title,
            sourceType: 1, //来源类型(0开发者平台 1运营平台)
          };
          this.getTableList(); // 成功后刷新列表
        }
      });
    },
    handleChangeStatus(row) {
      this.changeStatus({
        releaseStatus: row.releaseStatus === 1003 ? 1 : 0, //发布状态(0:上架1:下架)
        releaseId: row.releaseId,
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSave(formData, releaseType) {
      // 上架类型：1--普通，2--预约，3--内测，4--首发
      let fcn = saveUpdateReleaseInfo;
      if (releaseType === 2) {
        fcn = appReservedSave;
      } else if (releaseType === 3) {
        fcn = saveTestInfo;
      }
      fcn(formData).then((res) => {
        if (res && res.code === 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.operationType = "edit";
          this.$message.success("保存成功");
        }
      });
    },
    handleSubmit(formData, releaseType) {
      // 上架类型：1--普通，2--预约，3--内测，4--首发
      let fcn = submitReleaseInfo;
      if (releaseType === 2) {
        fcn = appReservedSubmit;
      } else if (releaseType === 3) {
        fcn = submitTestInfo;
      }
      fcn(formData).then((res) => {
        if (res && res.code === 0) {
          this.showArea = "list";
          this.dialogVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("提交成功");
        }
      });
    },
    handleCancel(showArea) {
      this.showArea = showArea;
      this.dialogVisible = false;
    },
    handleResetCreate() {
      this.$refs.createRef && this.$refs.createRef.resetFields();
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.releaseId);
    },
    handleBatchOperation() {
      if (this.selectionIdList.length == 0 || this.batchOperation === "") {
        const msg =
          this.batchOperation === ""
            ? "请选择操作类型"
            : "请勾选需要操作的数据";
        this.$message.error(msg);
        return;
      }
      this.changeStatus({
        releaseStatus: this.batchOperation,
        releaseId: this.selectionIdList.join(","),
      });
    },
    // 后端请求
    // 获取列表
    async getTableList(params, isDownload) {
      const data = {
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      if (data.date && data.date.length !== 0) {
        data.startTime = data.date[0];
        data.endTime = data.date[1];
      }
      if (this.serachKeyword) {
        data.chooseType = this.serachKeywordCode;
        data.keyword = this.serachKeyword;
      }
      const res = await getApkList(data);
      if (res) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    async getDetail(params, releaseType = 1) {
      // 上架类型：1--普通，2--预约，3--内测，4--首发
      let fcn = queryGeneral;
      if (releaseType === 2) {
        fcn = getReservedById;
      } else if (releaseType === 3) {
        fcn = queryTest;
      }
      let detailData = {};
      const res = await fcn(params);
      if (res && res.code === 0) {
        detailData = res.data;
      }
      return detailData;
    },
    // 获取语言列表
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    async appBack(params) {
      const res = await appBack(params);
      if (res && res.code === 0) {
        this.$message.success("版本回退成功");
        this.getTableList({
          page: this.tableData.page,
          size: this.tableData.size,
        }); // 成功后刷新列表
      }
    },
    async changeStatus(params) {
      const res = await changeStatus(params);
      if (res && res.code === 0) {
        this.getTableList({
          page: this.tableData.page,
          size: this.tableData.size,
        }); // 成功后刷新列表
        this.$message.success(res.msg);
      }
    },
    // 其他
    init() {
      this.getTableList();
      this.getLanguageList();
    },
    handleRatio(row) {
      this.editData = { ...row };
      this.ratioDialog = true;
    },
    handleCountry(row) {
      this.courntryList = row.releaseCountry.split(",");
      this.countryDialog = true;
      // lookCountry({ appInfoId: row.id }).then((res) => {
      //   if (res && res.code === 0) {
      //     this.countryDialog = true;
      //     this.courntryList = res.data;
      //   }
      // });
      // if (row.id == "1839596870519828481") {
      //   this.courntryList = [1, 2, 3];
      // } else {
      //   this.courntryList = [9, 8, 7];
      // }
      // this.countryDialog = true;
    },
    handleSubmitAudit(formData) {
      let data = {
        appInfoId: this.editData.id,
        ratio: formData.ratio,
      };
      editRatio(data).then((res) => {
        if (res && res.code === 0) {
          this.ratioDialog = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    async getDownloadList(callback) {
      const res = await this.getTableList({ page: 1, size: MAXSIZEDOWN }, true);
      if (res) {
        const headerDisplay = {};
        this.columns.forEach((item) => {
          const key = item.key || item.slot;
          if (key !== "action") {
            headerDisplay[key] = item.label;
          }
        });
        // 处理导出的数据
        const list = res.list.map((i) => {
          return {
            ...i,
            appCategoryId: this.$options.filters["typeDesc"](
              i.appCategoryId,
              this.appTypeList
            ),
            sourceType: this.$options.filters["typeDesc"](
              i.sourceType,
              this.sourceList
            ),
            releaseStatus: this.$options.filters["typeDesc"](
              i.releaseStatus,
              this.appStatusList
            ),
            releaseType: this.$options.filters["typeDesc"](
              i.releaseType,
              this.releaseTypeList
            ),
            reviewStatus: this.$options.filters["typeDesc"](
              i.reviewStatus,
              this.appReviewStatusList
            ),
            authDocStart: (i.authDocStart || "") + "--" + (i.authDocEnd || ""),
          };
        });
        callback({
          fileName: "应用列表",
          headerDisplay,
          list,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.input-with-select {
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 80px;
  }
}
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
